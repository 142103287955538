body{
  font-family: $ff_jp;
  @if $type == sp{
    font-size: 15px;
    img{
      max-width: 100%;
    }
  }
  @if $type == pc{
    font-size: 18px;
  }
}
.ff_jp{font-family: $ff_jp!important;}
.ff_eng{font-family: $ff_eng!important;}
li{list-style: none;}

.menu-gmenu-container{
  @if $type == sp{
  }
  @if $type == pc{
    background-color: #eceff1;
  }
  .menu{
    @if $type == sp{
      background-color: $c_base;
      padding-top: 50px;
    }
    @if $type == pc{
      width: 100%;
      max-width: 1200px;
      display: flex;
      margin: 0 auto;
    }
    li{
      @if $type == sp{
        padding: 0 15px;
        &:not(:first-of-type) a{
          border-top: 1px solid #57c2c6;
        }
      }
      @if $type == pc{
        width: 20%;
        transition: all .2s;
      }
      a{
        text-decoration: none;
        @if $type == sp{
          color: #fff;
          font-size: 20px;
          display: block;
          text-align: center;
          padding: 25px 0;
        }
        @if $type == pc{
          width: 100%;
          color: #000;
          font-size: 18px;
          min-height: 90px;
          height: 100%;;
          line-height: 1.5;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
      &.current-menu-item,&:hover{
        @if $type == pc{
          background-color: $c_base;
          a{
            color: #fff;
          }
        }
      }
    }
  }
}
#spBtns {
  @if $type == sp{
    height: 50px;
    width: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30;
    #menuBtn{
      height: 50px;
      width: 50px;
      background-color: #fff;
      span{
        width: 22px;
        height: 2px;
        border-radius: 2px;
        background-color: $c_base;
        display: block;
        position: absolute;
        left: 14px;
        transition: all .4s;
        &:nth-of-type(1){
          top: 16px;
        }
        &:nth-of-type(2){
          top: 25px;
        }
        &:nth-of-type(3){
          top: 34px;
        }
      }
      &.isOpen{
        background-color: $c_base;
        span{
          background-color: #fff;
          &:nth-of-type(1){
            top: 25px;
            transform: rotate(225deg);
          }
          &:nth-of-type(2){
            transform: rotate(180deg);
            opacity: 0;
          }
          &:nth-of-type(3){
            top: 25px;
            transform: rotate(-225deg);
          }
        }
      }
    }
  }
  @if $type == pc{
    display: none;
  }
}
.sp_slideMenu{
  @if $type == sp{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
  }
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background-color: #282828;
  opacity: 0.5;
  z-index: 10;
  top: 0;
  left: 0;
}

#pageTop{
  @if $type == sp{
  }
  @if $type == pc{
  }
}
footer{
  @if $type == sp{
  }
  @if $type == pc{
  }
  .footer_contact{
    background: url(../images/common/footer_contact_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    @if $type == sp{
      padding-top: 55px;
      padding-bottom: 80px;
    }
    @if $type == pc{
      width: 100%;
      height: 582px;
      padding-top: 100px;
    }
    .title{
      text-align: center;
      font-weight: bold;
      position: relative;
      color: #049196;
      text-shadow:0px 0px 4px #ffffff;
      @if $type == sp{
        font-size: 23px;
        padding-bottom: 15px;
      }
      @if $type == pc{
        font-size: 48px;
        padding-bottom: 20px;
      }
      &:after{
        content: "";
        display: inline-block;
        height: 2px;
        background-color: $c_base;
        position: absolute;
        bottom: 0;
        @if $type == sp{
          width: 100px;
          left: calc(50% - 50px);
        }
        @if $type == pc{
          width: 200px;
          left: calc(50% - 100px);
        }
      }
    }
    .text{
      @if $type == sp{
        margin-top: 30px;
        font-size: 15px;
      }
      @if $type == pc{
        text-align: center;
        font-size: 20px;
        max-width: 640px;
        margin: 50px auto 0;
      }
    }
    .btn{
      @if $type == sp{
        margin-top: 30px;
        text-align: center;
      }
      @if $type == pc{
        text-align: center;
        margin-top: 70px;
      }
    }
  }
}
.copyright{
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
  font-family: $ff_eng;
}

.common_btn{
  color: #fff;
  line-height: 1.3;
  background-color: $c_base;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  position: relative;
  @if $type == sp{
    font-size: 16px;
    min-height: 50px;
    padding: 15px;
    padding-right: 5px;
    width: 70%;
  }
  @if $type == pc{
    font-size: 20px;
    min-height: 74px;
    padding: 25px;
    padding-right: 10px;
    min-width: 325px;
    transition: all .3s;
    &:hover{
      opacity: .7;
    }
  }
  &:after{
    content: "";
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    top: 0;
    @if $type == sp{
      width: 25px;
      border: 25px solid transparent;
      border-left: 25px solid $c_base;
      right: -50px;
    }
    @if $type == pc{
      width: 37px;
      border: 37px solid transparent;
      border-left: 37px solid $c_base;
      right: -74px;
    }
  }
  &.orange{
    background-color: $c_orange;
    &:after{
      @if $type == sp{
        border-left: 25px solid $c_orange;
      }
      @if $type == pc{
      border-left: 37px solid $c_orange;
      }
    }
  }
}

.common_inner{
  @if $type == sp{
    padding: 0 15px;
  }
  @if $type == pc{
    width: 96%;
    max-width: 1200px;
    margin: 0 auto;
    &.w910{
      max-width: 910px;
    }
  }
}
#pageTop{
  background: url(../images/common/pagetop.png) no-repeat;
  background-size: cover;
  display: inline-block;
  position: fixed;
  bottom: 30px;
  z-index: 2;
  @if $type == sp{
    right: 15px;
    width: 50px;
    height: 50px;
  }
  @if $type == pc{
    right: 30px;
    width: 50px;
    height: 50px;
  }
}

.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}


/* import */

/* use Slick
@import "_slick";
@import "_slick-theme";
*/

/* ココから下には記入禁止 */

.forPC{  @if $type == sp{display: none!important;}}
.forSP{  @if $type == pc{display: none!important;}}
