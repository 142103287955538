@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none;
  vertical-align: top; }

table {
  border-collapse: collapse; }

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@media screen and (max-width: 800px) {
  body {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 15px; }
    body img {
      max-width: 100%; }
  .ff_jp {
    font-family: "Noto Sans JP", sans-serif !important; }
  .ff_eng {
    font-family: "Lato", sans-serif !important; }
  li {
    list-style: none; }
  .menu-gmenu-container .menu {
    background-color: #0ea7ad;
    padding-top: 50px; }
    .menu-gmenu-container .menu li {
      padding: 0 15px; }
      .menu-gmenu-container .menu li:not(:first-of-type) a {
        border-top: 1px solid #57c2c6; }
      .menu-gmenu-container .menu li a {
        text-decoration: none;
        color: #fff;
        font-size: 20px;
        display: block;
        text-align: center;
        padding: 25px 0; }
  #spBtns {
    height: 50px;
    width: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30; }
    #spBtns #menuBtn {
      height: 50px;
      width: 50px;
      background-color: #fff; }
      #spBtns #menuBtn span {
        width: 22px;
        height: 2px;
        border-radius: 2px;
        background-color: #0ea7ad;
        display: block;
        position: absolute;
        left: 14px;
        transition: all .4s; }
        #spBtns #menuBtn span:nth-of-type(1) {
          top: 16px; }
        #spBtns #menuBtn span:nth-of-type(2) {
          top: 25px; }
        #spBtns #menuBtn span:nth-of-type(3) {
          top: 34px; }
      #spBtns #menuBtn.isOpen {
        background-color: #0ea7ad; }
        #spBtns #menuBtn.isOpen span {
          background-color: #fff; }
          #spBtns #menuBtn.isOpen span:nth-of-type(1) {
            top: 25px;
            transform: rotate(225deg); }
          #spBtns #menuBtn.isOpen span:nth-of-type(2) {
            transform: rotate(180deg);
            opacity: 0; }
          #spBtns #menuBtn.isOpen span:nth-of-type(3) {
            top: 25px;
            transform: rotate(-225deg); }
  .sp_slideMenu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20; }
  #overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: #282828;
    opacity: 0.5;
    z-index: 10;
    top: 0;
    left: 0; }
  footer .footer_contact {
    background: url(../images/common/footer_contact_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 55px;
    padding-bottom: 80px; }
    footer .footer_contact .title {
      text-align: center;
      font-weight: bold;
      position: relative;
      color: #049196;
      text-shadow: 0px 0px 4px #ffffff;
      font-size: 23px;
      padding-bottom: 15px; }
      footer .footer_contact .title:after {
        content: "";
        display: inline-block;
        height: 2px;
        background-color: #0ea7ad;
        position: absolute;
        bottom: 0;
        width: 100px;
        left: calc(50% - 50px); }
    footer .footer_contact .text {
      margin-top: 30px;
      font-size: 15px; }
    footer .footer_contact .btn {
      margin-top: 30px;
      text-align: center; }
  .copyright {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 15px;
    font-family: "Lato", sans-serif; }
  .common_btn {
    color: #fff;
    line-height: 1.3;
    background-color: #0ea7ad;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    position: relative;
    font-size: 16px;
    min-height: 50px;
    padding: 15px;
    padding-right: 5px;
    width: 70%; }
    .common_btn:after {
      content: "";
      box-sizing: border-box;
      height: 100%;
      position: absolute;
      top: 0;
      width: 25px;
      border: 25px solid transparent;
      border-left: 25px solid #0ea7ad;
      right: -50px; }
    .common_btn.orange {
      background-color: #fb7000; }
      .common_btn.orange:after {
        border-left: 25px solid #fb7000; }
  .common_inner {
    padding: 0 15px; }
  #pageTop {
    background: url(../images/common/pagetop.png) no-repeat;
    background-size: cover;
    display: inline-block;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 15px;
    width: 50px;
    height: 50px; }
  /* import */
  /* use Slick
@import "_slick";
@import "_slick-theme";
*/
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important; } }

@media screen and (min-width: 800px) {
  body {
    font-family: "Noto Sans JP", sans-serif; }
  .ff_jp {
    font-family: "Noto Sans JP", sans-serif !important; }
  .ff_eng {
    font-family: "Lato", sans-serif !important; }
  li {
    list-style: none; }
  .menu-gmenu-container .menu li a {
    text-decoration: none; }
  #overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: #282828;
    opacity: 0.5;
    z-index: 10;
    top: 0;
    left: 0; }
  footer .footer_contact {
    background: url(../images/common/footer_contact_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center; }
    footer .footer_contact .title {
      text-align: center;
      font-weight: bold;
      position: relative;
      color: #049196;
      text-shadow: 0px 0px 4px #ffffff; }
      footer .footer_contact .title:after {
        content: "";
        display: inline-block;
        height: 2px;
        background-color: #0ea7ad;
        position: absolute;
        bottom: 0; }
  .copyright {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 15px;
    font-family: "Lato", sans-serif; }
  .common_btn {
    color: #fff;
    line-height: 1.3;
    background-color: #0ea7ad;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    position: relative; }
    .common_btn:after {
      content: "";
      box-sizing: border-box;
      height: 100%;
      position: absolute;
      top: 0; }
    .common_btn.orange {
      background-color: #fb7000; }
  #pageTop {
    background: url(../images/common/pagetop.png) no-repeat;
    background-size: cover;
    display: inline-block;
    position: fixed;
    bottom: 30px;
    z-index: 2; }
  /* import */
  /* use Slick
@import "_slick";
@import "_slick-theme";
*/
  /* ココから下には記入禁止 */ }

@media print, screen and (min-width: 801px) {
  body {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 18px; }
  .ff_jp {
    font-family: "Noto Sans JP", sans-serif !important; }
  .ff_eng {
    font-family: "Lato", sans-serif !important; }
  li {
    list-style: none; }
  .menu-gmenu-container {
    background-color: #eceff1; }
    .menu-gmenu-container .menu {
      width: 100%;
      max-width: 1200px;
      display: flex;
      margin: 0 auto; }
      .menu-gmenu-container .menu li {
        width: 20%;
        transition: all .2s; }
        .menu-gmenu-container .menu li a {
          text-decoration: none;
          width: 100%;
          color: #000;
          font-size: 18px;
          min-height: 90px;
          height: 100%;
          line-height: 1.5;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center; }
        .menu-gmenu-container .menu li.current-menu-item, .menu-gmenu-container .menu li:hover {
          background-color: #0ea7ad; }
          .menu-gmenu-container .menu li.current-menu-item a, .menu-gmenu-container .menu li:hover a {
            color: #fff; }
  #spBtns {
    display: none; }
  #overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: #282828;
    opacity: 0.5;
    z-index: 10;
    top: 0;
    left: 0; }
  footer .footer_contact {
    background: url(../images/common/footer_contact_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 582px;
    padding-top: 100px; }
    footer .footer_contact .title {
      text-align: center;
      font-weight: bold;
      position: relative;
      color: #049196;
      text-shadow: 0px 0px 4px #ffffff;
      font-size: 48px;
      padding-bottom: 20px; }
      footer .footer_contact .title:after {
        content: "";
        display: inline-block;
        height: 2px;
        background-color: #0ea7ad;
        position: absolute;
        bottom: 0;
        width: 200px;
        left: calc(50% - 100px); }
    footer .footer_contact .text {
      text-align: center;
      font-size: 20px;
      max-width: 640px;
      margin: 50px auto 0; }
    footer .footer_contact .btn {
      text-align: center;
      margin-top: 70px; }
  .copyright {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 15px;
    font-family: "Lato", sans-serif; }
  .common_btn {
    color: #fff;
    line-height: 1.3;
    background-color: #0ea7ad;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    position: relative;
    font-size: 20px;
    min-height: 74px;
    padding: 25px;
    padding-right: 10px;
    min-width: 325px;
    transition: all .3s; }
    .common_btn:hover {
      opacity: .7; }
    .common_btn:after {
      content: "";
      box-sizing: border-box;
      height: 100%;
      position: absolute;
      top: 0;
      width: 37px;
      border: 37px solid transparent;
      border-left: 37px solid #0ea7ad;
      right: -74px; }
    .common_btn.orange {
      background-color: #fb7000; }
      .common_btn.orange:after {
        border-left: 37px solid #fb7000; }
  .common_inner {
    width: 96%;
    max-width: 1200px;
    margin: 0 auto; }
    .common_inner.w910 {
      max-width: 910px; }
  #pageTop {
    background: url(../images/common/pagetop.png) no-repeat;
    background-size: cover;
    display: inline-block;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 30px;
    width: 50px;
    height: 50px; }
  /* import */
  /* use Slick
@import "_slick";
@import "_slick-theme";
*/
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important; } }
